import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

/**
 * MentalHealthAndCommitteesInfos component display infos for menatal health or committees page
 *
 * @param {object} icon 
 * @param {string} type 
 * @param {string} labelName 
 * @param {array} valueData
 * 
 * @returns {JSX Element}
 */
export default function MentalHealthAndCommitteesInfos({
  icon, type, labelName, valueData
}) {

  return (
    <div className="mental-committees__info-box">
      {icon?.file && <Img
        className="mental-committees__info-icon"
        fluid={icon.file.infoImageIcon?.fluid} />}
      {
        (() => {
          switch (type) {
            case 'Email':
              return <div className="mental-committees__info-text">
                <span>{labelName}</span>
                {valueData.map((valueItem, valueIndex) => {
                  return (
                    <a key={valueIndex} href={`mailto:${valueItem}`}>{valueItem}</a>
                  )
                })}
              </div>
            case 'Phone':
              return <div className="mental-committees__info-text">
                <span>{labelName}</span>
                {valueData.map((valueItem, valueIndex) => {
                  return (
                    <a key={valueIndex} href={`tel:${valueItem}`}>{valueItem}</a>
                  )
                })}
              </div>
            default:
              return <div className="mental-committees__info-text">
                <span>{labelName}</span>
                {valueData.map((valueItem, valueIndex) => {
                  return (
                    <p key={valueIndex}>{valueItem}</p>
                  )
                })}
              </div>
          }
        })()
      }
    </div>
  )
}

MentalHealthAndCommitteesInfos.propTypes = {
  icon: PropTypes.object,
  type: PropTypes.string,
  labelName: PropTypes.string,
  valueData: PropTypes.array,
}