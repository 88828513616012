import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import MentalHealthAndCommitteesLayout from '../components/MentalHealthAndCommitteesLayout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Seo from '../components/Seo'
import CookiePopUp from '../components/CookiePopUp'

/**
 * mentalHealthTemplate displaying mental-health and committees pages
 *
 * @param {object} data 
 * @param {object} pageContext 
 * 
 * @returns {JSX Element}
 */
export default function mentalHealthTemplate({ data }) {

  const { wpPage: { translations, language, title, mental_health_and_committees_intro, committees_mental_health: { contentRepeater } }, allWpMenu,
    allWp: { nodes: [optionsPage] } } = data

  const shareContent = mental_health_and_committees_intro.mcDescription && mental_health_and_committees_intro.mcDescription.replace(/<[^>]+>/g, '').slice(0, 160);


  return (
    <>
      <Seo title={title} description={shareContent} />
      <Header menuData={allWpMenu} language={language} translations={translations} />
      <MentalHealthAndCommitteesLayout introData={mental_health_and_committees_intro} contentData={contentRepeater} language={language} />
      <CookiePopUp language={language} />
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={optionsPage} />
    </>
  )
}


mentalHealthTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}
export const mentalCommitteesPageQuery = graphql`
  query mentalCommitteesPageByID($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      translations {
        slug
        uri
      }
      language {
        locale
      }
      mental_health_and_committees_intro {
        hasIntroHeader
        mcTitle
        mcDescription
      }
      committees_mental_health {
        contentRepeater {
          image {
            file: localFile {
              contentImage: childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          title
          description
          infoRepeater {
            infoIcon {
              file: localFile {
                infoImageIcon: childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            infoLabelName
            infoValue {
              fieldValue
            }
            infoType
          }
        }
      }
    }
    allWpMenu {
      ...getMenus
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData
      }
    }
  }
`