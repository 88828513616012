import React from 'react'
import PropTypes from 'prop-types'

/**
 * MentalHealthAndCommitteesIntro render intro/header for mental-healt and committees page
 *
 * @param {object} introData 
 *
 * @returns {JSX Element}
 */
export default function MentalHealthAndCommitteesIntro({ introData }) {

  const { mcTitle, mcDescription, hasIntroHeader } = introData
  
  return (
    hasIntroHeader && <div className="mental-committees__intro">
      <h2 className="mental-committees__intro-title">{mcTitle}</h2>
      <p className="mental-committees__intro-description">{mcDescription}
      </p>
    </div> 
  )
}

MentalHealthAndCommitteesIntro.propTypes = {
  introData: PropTypes.object
}
