import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import MentalHealthAndCommitteesInfos from './MentalHealthAndCommitteesInfos'

/**
 * MentalHealthAndCommiteesContent
 *
 * @param {array} contentDataFields 
 */
export default function MentalHealthAndCommiteesContent({ contentDataFields }) {
  return (
    <>
      {contentDataFields.map((item, index) => {
        return (
          <div className="mental-committees__box" key={index}>
            {item.image && <Img className="mental-committees__img" fluid={item.image.file.contentImage?.fluid} />}
            <div className="mental-committees__box-wrapper">
              <div className="mental-committees__content">
                <h3 className="mental-committees__content-title">{item.title}</h3>
                <div className="mental-committees__content-description" dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
              <div className="mental-committees__info">
                {item.infoRepeater &&
                  item.infoRepeater.map((infoItem, infoIndex) => {
                    return (
                      <MentalHealthAndCommitteesInfos
                        key={infoIndex}
                        icon={infoItem.infoIcon}
                        type={infoItem.infoType}
                        labelName={infoItem.infoLabelName}
                        valueData={infoItem.infoValue.map((value) => value.fieldValue)}
                      />
                    )
                  })
                }
              </div>
            </div>
          </div>)
      })
      }
    </>
  )
}

MentalHealthAndCommiteesContent.propTypes = {
  contentDataFields: PropTypes.array
}